<template>
  <section id="dashboard">
    <b-row class="match-height">
      <b-col
        lg="12"
        sm="12"
      >
        <user-pedidos-acesso />
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col
        lg="12"
        sm="12"
      >
        <user-utilizadores-ativos />
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import UserPedidosAcesso from './UserPedidosAcesso.vue'
import UserUtilizadoresAtivos from './UserUtilizadoresAtivos.vue'

export default {
  components: {
    BRow,
    BCol,
    UserPedidosAcesso,
    UserUtilizadoresAtivos,
  },
  data() {
    return {
      data: {},
    }
  },
  methods:
  {
    
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-user.scss';
</style>
